<template>
    <v-container>
        <v-row>
            <v-col
            class="mx-auto"
            cols="12"
            sm="6">
                <div class="d-flex justify-space-between">
                    <v-btn
                    class="mb-3"
                    color="primary"
                    @click="closeDetail">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </div>
                <v-skeleton-loader ref="skeleton" type="table" v-if="api.isLoading">
                </v-skeleton-loader>
                <v-card class="pt-3 pa-3" v-if="!api.isLoading &&notification!=null">
                    <v-toolbar color="secondary white--text text-h4 px-2">
                        Notification
                    </v-toolbar>
                    <v-card-title color="grey">
                        <v-icon size="80" color="grey">
                            mdi-account
                        </v-icon>
                        <v-col class="px-3">
                            <div class="text-h5 font-weight-bold">
                                {{ this.notification.fname }} {{ this.notification.lname }}
                            </div>
                            <div>
                                {{ this.notification.email }}
                            </div>
                            <div>
                                {{ this.notification.mobile }}
                            </div>
                        </v-col>
                    </v-card-title>
                    <v-card-title class="font-weight-bold text-h5">
                        {{ this.notification.title }}
                    </v-card-title>
                    <v-card-subtitle class="font-weight-bold pt-3">
                        {{ this.notification.created_at.split("T")[0] }}
                    </v-card-subtitle>
                    <v-card-text class="">
                        <router-link :to="{name:'PageDemonstrationUpdate',params:{id:JSON.parse(notification.data).id}}">
                            <span
                                class="text-h6">
                                View Detail
                            </span>
                        </router-link>
                    </v-card-text>
                    <v-card-text class="font-weight-bold text-h6 pt-3">
                    
                        <router-link
                        v-if="notification.company_id!=33" :to="{name:'PageCompaniesDetail',params:{id:notification.company_id}}">
                            {{ notification.company_name }}
                        </router-link>
                    </v-card-text>
                     <hr/>
                    <v-container>
                        <v-row no-gutters v-if="notification.type=='VisionFormAttachment'">
                            <v-col>
                                <v-card-title>
                                    <div class="text-h4">
                                        {{ this.description.attachment_type }}
                                    </div>
                                </v-card-title>
                                <v-card-title>
                                    <div class="text-h6">
                                        <a :href="this.description.attachment_url" target="_blank" >
                                            {{ this.description.attachment_name }}
                                        </a>
                                    </div>  
                                </v-card-title>
                            </v-col>
                        </v-row>
                        <v-row no-gutters v-if="notification.type=='VisionFormPayment'">
                            <v-col>
                                <v-card-title>
                                    <div class="text-h6">
                                        Payment status :
                                    </div>
                                    <div class="font-weight-bold text-h6 px-2">
                                        <span >
                                            {{ this.description.status }}
                                        </span>
                                    </div>
                                </v-card-title>
                                <v-card-title v-if="description.total_amount!=null">
                                    <div class="text-h6">
                                        Amount : 
                                    </div>
                                    <div class="font-weight-bold text-h6 px-2">
                                        <span >
                                            RM {{ this.description.total_amount }}
                                        </span>
                                    </div>
                                </v-card-title>
                                <v-card-title v-if="this.description.payment_method!=null">
                                    <div class="text-h6">
                                        Payment method :
                                    </div>
                                    <div class="font-weight-bold text-h6 px-2">
                                        {{ this.description.payment_method }}
                                    </div>
                                </v-card-title>
                                <!-- <v-card-text>
                                    <span class="font-weight-bold-px-2">
                                    </span>
                                </v-card-text> -->
                                <v-card-title v-if="this.description.invoice_no!=null">
                                    <div class="text-h6">
                                        Invoice No. :
                                    </div>
                                    <div class="font-weight-bold text-h6 px-2">
                                        {{ this.description.invoice_no}}
                                    </div>
                                </v-card-title>
                                <!-- <span class="font-weight">
                                    
                                </span> -->
                                <v-card-title>
                                    Remark :
                                </v-card-title>
                                <mavon-editor
                                style="z-index:0;min-height:100px;height:auto;width:100%"
                                width="auto"
                                defaultOpen="preview"
                                :toolbarsFlag="false"
                                :subfield="false"
                                language="en"
                                v-model="description.remark"
                                v-if="description.remark != null"
                                :editable="false">
                                </mavon-editor>
                                <mavon-editor
                                style="z-index:0;min-height:100px;height:auto;width:100%"
                                height="auto"
                                width="auto"
                                defaultOpen="preview" 
                                :toolbarsFlag="false" 
                                :subfield="false"
                                language="en"
                                v-model="isRemarkNull"
                                v-else
                                :editable="false">

                                </mavon-editor>
                            </v-col>
                        </v-row>
                        <v-row no-gutters v-if="notification.type=='VisionFormRenewal'">
                            <v-col>
                                <v-card-title>
                                    Renewal status : 
                                    <span class="font-weight-bold px-2">
                                        {{this.description.status }} ( {{this.description.renewal_status }} )
                                    </span>
                                </v-card-title>
                                <mavon-editor
                                style="z-index:0;min-height:100px;height:auto;width:100%"
                                width="auto"
                                defaultOpen="preview"
                                :toolbarsFlag="false"
                                :subfield="false"
                                language="en"
                                v-model="description.remark"
                                v-if="description.remark != null"
                                :editable="false">
                                </mavon-editor>
                                <mavon-editor
                                style="z-index:0;min-height:100px;height:auto;width:100%"
                                height="auto"
                                width="auto"
                                defaultOpen="preview" 
                                :toolbarsFlag="false" 
                                :subfield="false"
                                language="en"
                                v-model="isRemarkNull"
                                v-else
                                :editable="false">
                                </mavon-editor>
                            </v-col>
                        </v-row>
                        <v-card elevation="4" class="pt-3 mt-3 pa-3" v-if="this.notification.action!=null">
                            <v-row >
                                <v-col>
                                    <v-card-title>
                                        Action required :
                                    </v-card-title>
                                    <mavon-editor
                                    style="z-index:0;min-height:100px;height:auto;width:100%"
                                    height="auto"
                                    width="auto"
                                    defaultOpen="preview" 
                                    :toolbarsFlag="false" 
                                    :subfield="false"
                                    language="en"
                                    v-model="notification.action"
                                    :editable="false">
                                    </mavon-editor>
                                </v-col>
                                <!-- <v-col>
                                </v-col> -->
                            </v-row>
                        </v-card>
                    </v-container>
                    <!-- <v-data-table
                        :items = "this.team"
                        :headers = "this.headersTeam"
                        class="elevation--1">
                        <template v-slot:item.memberName="{item}">
                            {{ item.mfname }} {{ item.mlname }}
                        </template>
                    </v-data-table> -->
                </v-card>
               
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import mavonEditor from 'mavon-editor';
export default {
    components:{
        'mavon-editor':mavonEditor.mavonEditor,
    },
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
    notification:null,
    isRemarkNull:'No remark recorded',
    description:null,
    api:{
        isSuccesful:false,
        isLoading:false,
        isError:false,
        error:null,
        sucess:null,
        url:null,
    }
    }),
    created() {
        this.api.callbackReset = () => 
        {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            this.api.isSuccesful = true;
            this.api.isError = false;
            this.api.isLoading = false;
            if(resp.class=='readNotification')
            {
                resp.data.data =resp.data.data.replace('\n',' ');
                this.notification = resp.data;
                this.description = JSON.parse(resp.data.data)
                this.teamDescription = resp.data[0];
                this.teams = resp.data;
            }
          
            
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetchNotification(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/notification/read/"+this.$router.history.current.params.id;
            return tempApi;
        },
        fetchTickNotification(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/notification/tick/"+this.$router.history.current.params.id;
            return tempApi;
        },
        // fetchNewTotalNotification(){
        //     let tempApi = this.$_.clone(this.api);
        //     tempApi.method = "GET";
        //     tempApi.url = process.env.VUE_APP_SERVER_API+"/notification/total/user/"+this.$store.getters.getEmail;
        //     return tempApi;
        // },
        fetch(){
            let fetchTickNotificationApi = this.fetchTickNotification();
            // let fetchNewTotalNotificationApi = this.fetchNewTotalNotification();
            let fetchNotificationApi = this.fetchNotification();
            this.$axios.all([
                this.$api.fetch(fetchNotificationApi),
                this.$api.fetch(fetchTickNotificationApi),
                // this.$api.fetch(fetchNewTotalNotificationApi),
        ])

        },
        closeDetail(){
            this.$router.go(-1);
        },
        redirectCompany(companyId){
            this.$store.commit("updateCompany",companyId);
            const route = this.$router.resolve({name: 'PageCompaniesDetail',params:{'id':companyId}});
            window.open(route.href, '_blank');
        }
    }
}
</script>